import styled from "styled-components";

const Page = styled.section`
    margin: 0;
    padding: 0;
`;


export {
    Page
}